<template>
  <div>
    <validation-observer ref="simpleRules">
      <button
        class="btn btn-gradient-primary mb-1"
        @click="Submit()"
      >
        {{ this.$t('saveData') }}
      </button>
      <b-row>
        <b-col md="8">

          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ this.$t('personalInfo') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('firstName') + '-' + $t('lastName') }} <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="fullname"
                      rules="required"
                    >
                      <b-form-input
                        id="h-product-name"
                        v-model="fullname"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-product-id"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('email') }} <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="h-product-id"
                        v-model="email"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-phone-number"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('phoneNumber') }} <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Phone Number"
                      rules="required"
                    >
                      <b-form-input
                        id="h-phone-number"
                        v-model="tel"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-phone-number2"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('phoneNumber') }}(2) :
                    </template>
                    <!-- <validation-provider
                      #default="{ errors }"
                      name="Phone"
                      rules="required"
                    > -->
                    <b-form-input
                      id="h-phone-number2"
                      v-model="tel2"

                      type="text"
                    />
                    <!-- :state="errors.length > 0 ? false : null" -->
                    <!-- <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider> -->
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-IDLine"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      ID LINE <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="IDLine"
                      rules="required"
                    >
                      <b-form-input
                        id="h-IDLine"
                        v-model="line_id"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-IDWeChat"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      ID WeChat :
                    </template>
                    <!-- <validation-provider
                      #default="{ errors }"
                      name="IDWeChat"
                      rules="required"
                    >  -->
                    <b-form-input
                      id="h-IDWeChat"
                      v-model="wechat_id"
                      type="text"
                    />
                    <!-- :state="errors.length > 0 ? false : null" -->
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <!-- </validation-provider> -->
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-different"
                    label-cols-md="3"
                    label-cols="3"
                    label-cols-lg="3"
                  >
                    <template v-slot:label>
                      {{ $t('key-237') }} :
                    </template>
                    <!-- <validation-provider
                      #default="{ errors }"
                      name="Different"
                      rules="required"
                    > -->
                    <b-form-input
                      id="h-different"
                      v-model="invited_by"
                      type="text"
                    />
                    <!-- :state="errors.length > 0 ? false : null" -->
                    <!-- <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider> -->
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-different"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('different') }} <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Different"
                      rules="required"
                    >
                      <b-form-input
                        id="h-different"
                        v-model="different"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!--
                <b-col md="12">
                  <b-form-group
                    label-for="h-invite"
                    label-cols="3"
          label-cols-lg="3"
          label-cols-md="3"
                  >
                    <template v-slot:label>
                      รหัสผู้แนะนำ <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Invite Code"
                      rules="required"
                    >
                      <v-select
                        id="h-invite"
                        v-model="invited_by"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false : null"
                        label="name"
                        :options="items"
                        class="w-100 mr-1"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->

                <b-col md="12">
                  <b-form-group
                    label-for="h-invite"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('level') }} <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Invite Code"
                      rules="required"
                    >
                      <v-select
                        id="h-invite"
                        v-model="role"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false : null"
                        label="text"
                        :options="rolelist"
                        class="w-100 mr-1"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="card">
            <div class="header-cardx p-2 d-flex justify-content-between">
              <h3 class="mb-0">
                {{ $t('key-237') }}
              </h3>
              <div>
                <b-form-checkbox
                  v-model="ReferralCode"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </div>

            </div>

            <div
              v-if="ReferralCode"
              class="p-2"
            >
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-different"
                    label-cols="5"
                    label-cols-md="3"
                    label-cols-lg="3"
                  >
                    <template v-slot:label>
                      {{ $t('key-237') }} <span class="text-danger">*</span> :
                    </template>
                    <b-form-input
                      id="h-different"
                      v-model="ReferralCodeValue"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('accessDetails') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('customerCode') }} <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Product Name"
                      rules="required|englishNumber|lengthBetween|englishNumberNoSpecial"
                    >
                      <b-form-input
                        id="h-product-name"
                        v-model="username"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="login-password"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('password') }}
                      <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="card">
            <div class="header-cardx p-2 d-flex justify-content-between align-items-center">
              <h3 class="mb-0">
                {{ $t('key-197') }} <span class="text-danger">*</span>
              </h3>
              <button
                v-if="!itemCart"
                class="btn btn-gradient-primary"
                @click="showAddAddrCart()"
              >
                <i class="far fa-plus" /> {{ $t('addAddress') }}
              </button>
            </div>
            <div
              v-if="itemCart"
              class="view"
            >
              <div class="icon">
                <i class="fas fa-map-marker-alt fa-2x text-success" />
              </div>
              <div class="content">
                <div class="grider">
                  <div class="label">
                    {{ $t('address') }}
                  </div>
                  <div class="detail">
                    {{ itemCart.address }} <br>
                    {{ $t('province') }} {{ itemCart.province_name }} <br>
                    {{ $t('district') }} {{ itemCart.district_name }} <br>
                    {{ $t('subdistrict') }} {{ itemCart.subdistrict_name }} <br>
                    {{ $t('postalCode') }} {{ itemCart.zipcode }}
                  </div>
                </div>
              </div>
              <div class="operations text-r">
                <div class="edit">
                  <button
                    type="button"
                    class="anan-button anan-button--link anan-button--normal"
                    @click="showAddAddrCart(itemCart)"
                  >
                    <span> {{ $t('edit') }} </span>
                  </button>
                </div>
                <div class="delete">
                  <button
                    type="button"
                    class="anan-button anan-button--link anan-button--normal"
                    @click="deleteAddrCart()"
                  >
                    <span> {{ $t('delete') }} </span>
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class="p-2">
              <b-row>
                <b-col md="12">

                  <b-form-group
                    label-for="h-addressCart"
                    :label="this.$t('addressNumber')"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="AddressCart"
                      rules="required"
                    >
                      {{items.user.address}}
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="12"
                >
                  <b-form-group
                    label-for="h-province-nameCart"
                    :label="this.$t('province')"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="ProvinceCart"
                      rules="required"
                    >
                      <v-select
                        v-model="items.user.province"
                        label="province_name"
                        :options="provinceOptionsCart"
                        :is-disabled="errors.length > 0"
                        :reduce="ele => ele.province_id"
                        @input="updateDistricts"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="12"
                >
                  <b-form-group
                    label-for="h-district-name"
                    :label="this.$t('district')"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="District"
                      rules="required"
                    >
                      <v-select
                        v-model="selectedDistrict"
                        label="district_name"
                        :options="filteredDistricts"
                        :is-disabled="!selectedProvince"
                        :reduce="ele => ele.district_id"
                        @input="updateSubDistricts"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="12"
                >
                  <b-form-group
                    label-for="h-sub-district-name"
                    :label="this.$t('subdistrict')"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Sub District"
                      rules="required"
                    >
                      <v-select
                        id="h-sub-district-name"
                        v-model="selectedSubDistrict"
                        label="sub_district_name"
                        :options="filteredSubDistricts"
                        :state="errors.length > 0 ? false : null"
                        :reduce="ele => ele.tumbon_id"
                        @input="updatePostalcode"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label-for="h-postal-code"
                    :label="this.$t('postalCode')"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Postal Code"
                      rules="required"
                    >
                      <b-form-input
                        id="h-postal-code"
                        v-model="postalcode"
                        label="zipcode"
                        :state="errors.length > 0 ? false : null"
                        :disabled="postalcode"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div> -->
          </div>
          <div class="card">
            <div
              class="header-cardx p-2 d-flex justify-content-between align-items-center"
            >
              <div>
                <h3 class="mb-0">
                  {{ $t('shippingAddress') }}
                </h3>
                <small
                  class="text-muted"
                >{{ $t('setDeliveryAddress') }}  </small>
              </div>

              <button
                class="btn btn-gradient-primary"
                @click="showAddAddr(),titleModal = $t('key-238')"
              >
                <i class="far fa-plus" /> {{ $t('addAddress') }}
              </button>
            </div>

            <div
              v-for="(items, index) in Address"
              :key="items"
              class="view"
            >
              <div class="icon">
                <i class="fas fa-map-marker-alt fa-2x text-success" />
              </div>
              <div class="content">
                <div class="grider">
                  <div class="label">
                    {{ $t('key-77') }}
                  </div>
                  <div class="name">
                    {{ items.fullname }}
                  </div>
                </div>
                <div class="grider">
                  <div class="label">
                    {{ $t('key-146') }}
                  </div>
                  <div class="phone">
                    {{ items.tel }}
                  </div>
                </div>
                <div class="grider">
                  <div class="label">
                    {{ $t('address') }}
                  </div>
                  <div class="detail">
                    {{ items.address }} <br>
                    {{ $t('province') }}{{ items.province }} <br>
                    {{ $t('key-102') }}{{ items.district }} {{ items.subdistrict }} {{ items.postalcode }}
                  </div>
                </div>
              </div>
              <div class="operations text-r">
                <div class="edit">
                  <button
                    type="button"
                    class="anan-button anan-button--link anan-button--normal"
                    @click="showAddAddr(items,index)"
                  >
                    <span> {{ $t('edit') }} </span>
                  </button>
                </div>
                <div class="delete">
                  <button
                    type="button"
                    class="anan-button anan-button--link anan-button--normal"
                    @click="deleteAddr(items,index)"
                  >
                    <span> {{ $t('delete') }} </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div
              class="header-cardx p-2 d-flex justify-content-between align-items-center"
            >
              <div>
                <h3 class="mb-0">
                  {{ $t('bankAccount') }}
                </h3>
              </div>

              <button
                v-if="itemBank.length < 2"
                class="btn btn-gradient-primary"
                @click="EditType(),titleBank = $t('addBankAccount')"
              >
                <i class="far fa-plus" /> {{ $t('key-239') }}
              </button>
            </div>

            <div class="pl-2 pr-2">
              <b-table
                responsive="sm"
                :fields="fieldsbank"
                :items="itemBank"
                show-empty
              >
                <template #empty="">
                  <b-table-empty-slot>
                    <div class="text-center my-2">
                      <img
                        src="@/assets/images/anan-img/svg/doc_empty.svg"
                        alt="empty"
                        height="60px"
                      >
                      <p class="text-muted">
                        No Data
                      </p>
                    </div>
                  </b-table-empty-slot>
                </template>
                <template #cell(set)="data">
                  <b-form-checkbox
                    v-model="checkeditem"
                    :value="data.item"
                    :unchecked-value="null"
                  />
                </template>
                <template #cell(branch)="data">
                  {{ data.item.branch ? data.item.branch : "-" }}
                </template>

                <template #cell(actions)="data">
                  <feather-icon
                    icon="TrashIcon"
                    class="text-danger cursor-pointer"
                    @click="DeleteData(data.item, data.index)"
                  />
                  <!-- {{ data.item.branch ? data.item.branch: '-' }} -->
                </template>
              </b-table>
            </div>
          </div>

          <!-- modal edit bank -->
          <b-modal
            id="modal-receive-add-amend"
            :title="titleBank"
            :ok-title="$t('key-13')"
            :cancel-title="$t('cancel')"
            @ok.prevent="addNewbank()"
          >
            <validation-observer ref="addNewbank">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-name_bank"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      <span class="text-danger"> *</span> {{ $t("key-55") }}
                    </template>
                    <v-select
                      v-model="bank_obj"
                      class="style-chooser"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="itemBankList"
                    >
                      <template #option="{ name, path_photo }">
                        <img
                          :src="`${path_photo}`"
                          :alt="name"
                          height="30"
                          class="mr-50 rounded-sm"
                        >
                        <span>{{ name }}</span>
                      </template>

                      <template #selected-option="{ name, path_photo }">
                        <img
                          :src="`${path_photo}`"
                          :alt="name"
                          height="30"
                          class="mr-50"
                        >
                        <span>{{ name }}</span>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-addresstex"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      <span class="text-danger"> *</span>
                      {{ $t("accountName") }}
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="name_account"
                      rules="required"
                    >
                      <b-form-input
                        id="h-name_bank"
                        v-model="name_account"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{
                        errors[0] ? $t("requiredField") + "!" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-number_account"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      <span class="text-danger"> *</span>
                      {{ $t("accountNumber") }}
                    </template>

                    <validation-provider
                      #default="{ errors }"
                      name="number_account"
                      rules="required"
                    >
                      <b-form-input
                        id="h-number_account"
                        v-model="number_account"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{
                        errors[0] ? $t("requiredField") + "!" : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </b-modal>

          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('userGroup') }}
              </h3>
            </div>

            <div class="p-2">
              <b-form-group
                label-for="h-catagory-name"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  {{ $t('userGroup') }} <span class="text-danger">*</span>
                </template>
                <div class="d-flex align-items-center">
                  <v-select
                    id="h-catagory-name"
                    v-model="group_use"
                    label="name"
                    :options="group_user_list"
                    class="w-100 mr-1"
                  />
                  <router-link
                    v-if="!group_use"
                    :to="{name: 'admin-user-type'}"
                    target="_blank"
                  >
                    <i
                      class="far fa-edit text-primary cursor-pointer"
                    />
                  </router-link>
                  <router-link
                    v-else
                    :to="{name: 'admin-user-type-edit', params: {id: group_use._id}}"
                    target="_blank"
                  >
                    <i
                      class="far fa-edit text-primary cursor-pointer"
                    />
                  </router-link>
                  <i
                    :class="`fas fa-sync-alt ${group_userloading ? 'fa-spin' : ''} text-primary cursor-pointer ml-1`"
                    @click="GetGroupUserList()"
                  />
                </div>
              </b-form-group>

              <b-form-group
                v-if="group_use"
                label-for="h-catagory-name"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  {{ $t('type') }} <span class="text-danger">*</span>
                </template>
                <div class="d-flex align-items-center">
                  <b-form-radio
                    v-model="group_user_type"
                    name="some-radios"
                    :value="1"
                    class="mb-1 mt-1"
                    @input="CalGroupUser()"
                  >
                    {{ $t('defaultValue') }}
                  </b-form-radio>

                  <b-form-radio
                    v-model="group_user_type"
                    name="some-radios"
                    class="ml-1"
                    :value="2"
                  >
                    {{ $t('customized') }}
                  </b-form-radio>
                </div></b-form-group>

              <div
                v-if="group_use"
                class="row"
              >
                <div class="col-md-6">
                  <template
                    v-if="group_user_type == 1"
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">
                            {{ $t('truck') }}
                          </th>
                          <th scope="col">
                            {{ $t('kilo') }}.

                          </th>
                          <th scope="col">
                            {{ $t('cubic') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="car in preorder(group_use.product_type, 1).slice().reverse()"
                          :key="car.id"
                        >
                          <td>{{ car.product_type_name }}</td>
                          <td>{{ car.product_type_kilo }}</td>
                          <td>{{ car.product_type_queue }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                  <template
                    v-if="group_user_type == 2"
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">
                            {{ $t('truck') }}
                          </th>
                          <th scope="col">
                            {{ $t('kilo') }}.
                          </th>
                          <th scope="col">
                            {{ $t('cubic') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in preorder(group_use.product_type, 1).slice().reverse()"
                          :key="item.id"
                        >
                          <td>{{ item.product_type_name }}</td>
                          <td>
                            <b-form-input
                              v-model="item.product_type_kilo"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model="item.product_type_queue"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                  <!-- <b-table
                    responsive="sm"
                    :fields="fieldsCar"
                    :items="itemsCar"
                    class="mb-2"
                  >
                    <template
                      v-if="group_user_type == 2"
                      #cell(kg)="data"
                    >
                      <b-form-input
                        v-model="data.item.kg"
                        type="number"
                      />
                    </template>

                    <template
                      v-if="group_user_type == 2"
                      #cell(queue)="data"
                    >
                      <b-form-input
                        v-model="data.item.queue"
                        type="number"
                      />
                    </template>
                  </b-table> -->
                </div>
                <div class="col-md-6">
                  <template
                    v-if="group_user_type == 1"
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">
                            {{ $t('truck') }}
                          </th>
                          <th scope="col">
                            {{ $t('kilo') }}.
                          </th>
                          <th scope="col">
                            {{ $t('cubic') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in preorder(group_use.product_type, 2).slice().reverse()"
                          :key="item.id"
                        >
                          <td>{{ item.product_type_name }}</td>
                          <td>{{ item.product_type_kilo }}</td>
                          <td>{{ item.product_type_queue }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                  <template
                    v-if="group_user_type == 2"
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">
                            {{ $t('ship') }}
                          </th>
                          <th scope="col">
                            {{ $t('kilo') }}.
                          </th>
                          <th scope="col">
                            {{ $t('cubic') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in preorder(group_use.product_type, 2).slice().reverse()"
                          :key="item.id"
                        >
                          <td>{{ item.product_type_name }}</td>

                          <td>
                            <b-form-input
                              v-model="item.product_type_kilo"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model="item.product_type_queue"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                  <!-- <b-table
                    responsive="sm"
                    :fields="fieldsShip"
                    :items="itemsShip"
                  >
                    <template
                      v-if="group_user_type == 2"
                      #cell(kg)="data"
                    >
                      <b-form-input
                        v-model="data.item.kg"
                        type="number"
                      />
                    </template>

                    <template
                      v-if="group_user_type == 2"
                      #cell(queue)="data"
                    >
                      <b-form-input
                        v-model="data.item.queue"
                        type="number"
                      />
                    </template>
                  </b-table> -->
                </div>
              </div>

            </div>
          </div>
        </b-col>

        <b-col md="4">
          <div
            class="card"
          >
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('moduleDetails') }}
              </h3>
            </div>

            <div class="p-2 card-module">
              <b-form-group
                label-for="h-catagory-name"
                label-cols="3"
                label-cols-lg="3"
                label-cols-md="3"
              >
                <template v-slot:label>
                  {{ $t('License') }} <span class="text-danger">*</span>
                </template>
                <div class="d-flex align-items-center">
                  <v-select
                    id="h-catagory-name"
                    v-model="module_use"
                    :options="module_list"
                    label="name"
                    :reduce="text => text"
                    class="w-100 mr-1"
                    @input="CalModule()"
                  />
                  <!-- <i
                    class="far fa-edit text-primary cursor-pointer"
                    @click="showModal"
                  /> -->
                </div>
              </b-form-group>

              <div>
                <div
                  v-for="item in subrole"
                  :key="item.name"
                  class="d-flex justify-content-between mb-1 mt-2 align-items-center border-bottom"
                >
                  <div>
                    <h5 class="mb-0 font-weight-bolder">
                      {{ item.name }}
                    </h5>
                  </div>
                  <div>
                    <i
                      :class="`fas ${item.status ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'}`"
                    />
                    <!-- @click="item.status = !item.status , SubRole(item)" -->
                  </div>
                </div>
              </div>

            </div>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <!-- เพิ่มที่อยู่ใหม่ -->
    <b-modal
      id="add-addr"
      :title="titleModal"
    >
      <validation-observer ref="addRules">
        <b-form-group
          label-for="h-name"
          :label="this.$t('firstName') + '-' + this.$t('lastName')"
        >
          <validation-provider
            #default="{ errors }"
            name="Full Name"
            rules="required"
          >
            <b-form-input
              id="h-name"
              v-model="name"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-phone-number"
          :label="this.$t('mobileNumber')"
        >
          <validation-provider
            #default="{ errors }"
            name="Phone Number"
            rules="required"
          >
            <b-form-input
              id="h-phone-number"
              v-model="phone"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-address"
          :label="this.$t('addressNumber')"
        >
          <validation-provider
            #default="{ errors }"
            name="Address"
            rules="required"
          >
            <b-form-textarea
              id="h-address"
              v-model="address"
              row="2"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-province-name"
          :label="this.$t('province')"
        >
          <validation-provider
            #default="{ errors }"
            name="Province"
            rules="required"
          >
            <v-select
              v-model="selectedProvince"
              label="province_name"
              :options="provinceOptions"
              :is-disabled="errors.length > 0"
              :reduce="ele => ele.province_id"
              @input="updateDistricts"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-district-name"
          :label="this.$t('district')"
        >
          <validation-provider
            #default="{ errors }"
            name="District"
            rules="required"
          >
            <v-select
              v-model="selectedDistrict"
              label="district_name"
              :options="filteredDistricts"
              :is-disabled="!selectedProvince"
              :reduce="ele => ele.district_id"
              @input="updateSubDistricts"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-sub-district-name"
          :label="this.$t('subdistrict')"
        >
          <validation-provider
            #default="{ errors }"
            name="Sub District"
            rules="required"
          >
            <v-select
              id="h-sub-district-name"
              v-model="selectedSubDistrict"
              label="sub_district_name"
              :options="filteredSubDistricts"
              :state="errors.length > 0 ? false : null"
              :reduce="ele => ele.tumbon_id"
              @input="updatePostalcode"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-postal-code"
          :label="this.$t('postalCode')"
        >
          <validation-provider
            #default="{ errors }"
            name="Postal Code"
            rules="required"
          >
            <b-form-input
              id="h-postal-code"
              v-model="postalcode"
              label="zipcode"
              :state="errors.length > 0 ? false : null"
              :disabled="postalcode"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
      <template #modal-footer="{ cancel }">
        <b-button
          @click="copycart(index)"
        >
          <!-- v-if="titleModal === 'เพิ่มที่อยู่จัดส่งสินค้า'" -->
          {{ $t('key-240') }}
        </b-button>
        <b-button
          variant="danger"
          @click="cancel"
        >
          {{ $t('cancel') }}
        </b-button>
        <b-button
          variant="primary"
          @click="addAddr(index)"
        >
          {{ $t('key-124') }}
        </b-button>
      </template>
    </b-modal>
    <!-- เพิ่มที่อยู่บัตรประชาชน -->
    <b-modal
      id="add-addr-cart"
      :title="$t('key-241')"
      no-close-on-backdrop
      @ok.prevent="addcart()"
    >
      <validation-observer ref="addRulescart">

        <b-form-group
          label-for="h-address-cart"
          :label="this.$t('addressNumber')"
        >
          <validation-provider
            #default="{ errors }"
            name="Address-cart"
            rules="required"
          >
            <b-form-textarea
              id="h-address-cart"
              v-model="addressCart"
              row="2"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-for="h-province-name-cart"
          :label="this.$t('province')"
        >
          <validation-provider
            #default="{ errors }"
            name="Province-cart"
            rules="required"
          >
            <v-select
              id="h-province-name-cart"
              v-model="selectedProvinceCart"
              label="province_name"
              :options="provinceOptions"
              :state="errors.length > 0 ? false : null"
              :reduce="ele => ele.province_id"
              @input="updateDistrictsCart"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-district-name-cart"
          :label="this.$t('district')"
        >
          <validation-provider
            #default="{ errors }"
            name="District-cart"
            rules="required"
          >
            <v-select
              id="h-district-name-cart"
              v-model="selectedDistrictCart"
              label="district_name"
              :options="filteredDistrictsCart"
              :state="errors.length > 0 ? false : null"
              :reduce="ele => ele.district_id"
              @input="updateSubDistrictsCart"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-sub-district-name-cart"
          :label="this.$t('subdistrict')"
        >
          <validation-provider
            #default="{ errors }"
            name="Sub District"
            rules="required"
          >
            <v-select
              id="h-sub-district-name-cart"
              v-model="selectedSubDistrictCart"
              label="sub_district_name"
              :options="filteredSubDistrictsCart"
              :state="errors.length > 0 ? false : null"
              :reduce="ele => ele.tumbon_id"
              @input="updatePostalcodeCart"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-postal-code-cart"
          :label="this.$t('postalCode')"
        >
          <validation-provider
            #default="{ errors }"
            name="Postal Code-cart"
            rules="required"
          >
            <b-form-input
              id="h-postal-code-cart"
              v-model="zipcodeCart"
              label="zipcode"

              :state="errors.length > 0 ? false : null"
              :disabled="zipcodeCart"
            />
            <!-- <div
              v-for="item in CalpostalCode(subdistrict)"
              :key="item.tum_id"
            >
              <div
                v-if="postalcode = item.zipcode"
                id="zipcode"
              /> -->
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BModal,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BFormRadio,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required, email, regex, between,
} from '@validations'
import vSelect from 'vue-select'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'

extend('englishNumber', {
  ...regex,
  message: 'กรุณากรอกข้อมูลเป็นภาษาอังกฤษและตัวเลข',
  validate: value => /^[A-Za-z0-9]+$/.test(value) && /[A-Za-z]/.test(value),
})
extend('englishNumberNoSpecial', {
  ...regex,
  message: 'ห้ามระบุตัวอักษรพิเศษเช่น @ _ $ &',
  validate: value => /^[A-Za-z0-9]*$/.test(value),
})

extend('lengthBetween', {
  ...between,
  message: 'กรุณากรอกข้อมูลมากกว่า 4 ตัวอักษร ไม่เกิน 8 ตัวอักษร',
  validate: value => value.length >= 4 && value.length <= 8,
})

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BFormRadio,
    BButton,
    BFormCheckbox,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      ReferralCode: false,
      ReferralCodeValue: null,
      fullname: null,
      tel: null,
      tel2: null,
      different: null,
      invited_by: null,
      role: null,
      username: null,
      password: null,
      sub_role: [],
      Address: [],

      // Add Address
      name: null,
      phone: null,
      address: null,
      province: null,
      district: null,
      subdistrict: null,
      postalcode: null,

      selectedFiles: [],
      uploadedImages: [],
      fieldsCar: [
        { key: 'car', label: 'รถยนต์', thStyle: { width: '50%' } },
        { key: 'kg', label: 'กิโล.', thStyle: { width: '25%' } },
        { key: 'queue', label: 'คิว.', thStyle: { width: '25%' } },
      ],
      fieldsShip: [
        { key: 'ship', label: 'เรือ', thStyle: { width: '50%' } },
        { key: 'kg', label: 'กิโล.', thStyle: { width: '25%' } },
        { key: 'queue', label: 'คิว.', thStyle: { width: '25%' } },
      ],
      itemsCar: [
        {
          car: 'A', kg: 15, queue: 6500, key_kg: 'car_a_kilo', key_q: 'car_a_q',
        },
        {
          car: 'B', kg: 30, queue: 7000, key_kg: 'car_b_kilo', key_q: 'car_b_q',
        },
        {
          car: 'C', kg: 40, queue: 9000, key_kg: 'car_c_kilo', key_q: 'car_c_q',
        },
        {
          car: 'D', kg: 100, queue: 12000, key_kg: 'car_d_kilo', key_q: 'car_d_q',
        },
        {
          car: 'D1', kg: 180, queue: 15000, key_kg: 'car_d1_kilo', key_q: 'car_d1_q',
        },
        {
          car: 'S', kg: 70, queue: 8500, key_kg: 'car_s_kilo', key_q: 'car_s_q',
        },
      ],
      itemsShip: [
        {
          ship: 'A', kg: 10, queue: 4000, key_kg: 'ship_a_kilo', key_q: 'ship_a_q',
        },
        {
          ship: 'B', kg: 20, queue: 6000, key_kg: 'ship_b_kilo', key_q: 'ship_b_q',
        },
        {
          ship: 'C', kg: 30, queue: 9000, key_kg: 'ship_c_kilo', key_q: 'ship_c_q',
        },
      ],
      subrole: [],
      rolelist: [
        // employee
        { text: 'แอดมิน', value: 1 },
        { text: 'ลูกค้า', value: 0 },
        { text: 'พนักงาน', value: 2 },
      ],
      del_address: [],
      itemspvc: [],
      idistrict: [],
      iSubdistrict: [],
      izipcode: [],
      subDistrict: [],
      postalCode: null,
      module_list: [],
      module_use: '',
      group_user_list: [],
      group_use: '',
      zipcode: null,
      selectedProvince: null,
      selectedDistrict: null,
      selectedSubDistrict: null,
      // group_user_type: 1,
      group_userloading: false,
      category: '',
      group_user_type: 1,
      group_name: null,
      group_detail: null,
      product_type: null,
      index: null,
      bank_obj: null,
      itemBank: [],
      itemBankList: [],
      titleModal: null,
      titleBank: null,
      selectedProvinceCart: null,
      selectedDistrictCart: null,
      selectedSubDistrictCart: null,
      addressCart: null,
      zipcodeCart: null,
      itemCart: null,
      line_id: null,
      wechat_id: null,
      id: 0,
      itemcheck: false,
      itemnew: null,
    }
  },
  computed: {
    fieldsbank() {
      return [
        {
          key: 'set',
          label: '',
          thStyle: { width: '5%' },
          thClass: 'text-center',
        },
        {
          key: 'name_bank',
          label: this.$t('key-55'),
          thStyle: { width: '15%' },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'name_account',
          label: this.$t('accountName'),
          thStyle: { width: '15%' },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'number_account',
          label: this.$t('accountNumber'),
          thStyle: { width: '15%' },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        // {
        //   key: 'branch', label: this.$t('branch'), thStyle: { width: '15%' }, tdClass: 'text-center', thClass: 'text-center',
        // },
        {
          key: 'actions',
          label: this.$t('manage'),
          thStyle: { width: '10%' },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ]
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    provinceOptions() {
      return this.itemspvc.map(item => ({
        province_id: item.province_id,
        province_name: item.province_name,
      }))
    },
    filteredDistricts() {
      if (this.selectedProvince) {
        return this.idistrict.filter(ele => (ele.province_id === this.selectedProvince))
          .map(item => ({
            district_id: item.district_id,
            district_name: item.district_name,
            province_id: item.province_id,
          }))
      }
      return this.idistrict.map(item => ({
        district_id: item.district_id,
        district_name: item.district_name,
        province_id: item.province_id,
      }))
    },
    filteredSubDistricts() {
      if (this.selectedDistrict) {
        return this.iSubdistrict.filter(ele => (ele.ampur_id === this.selectedDistrict))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      if (this.selectedProvince) {
        return this.iSubdistrict.filter(ele => (ele.changwat_id === this.selectedProvince))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      return this.iSubdistrict.map(item => ({
        ampur_id: item.ampur_id,
        sub_district_name: item.sub_district_name,
        tumbon_id: item.tumbon_id,
        changwat_id: item.changwat_id,
      }))
    },
    filteredDistrictsCart() {
      if (this.selectedProvinceCart) {
        return this.idistrict.filter(ele => (ele.province_id === this.selectedProvinceCart))
          .map(item => ({
            district_id: item.district_id,
            district_name: item.district_name,
            province_id: item.province_id,
          }))
      }
      return this.idistrict.map(item => ({
        district_id: item.district_id,
        district_name: item.district_name,
        province_id: item.province_id,
      }))
    },
    filteredSubDistrictsCart() {
      if (this.selectedDistrictCart) {
        return this.iSubdistrict.filter(ele => (ele.ampur_id === this.selectedDistrictCart))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      if (this.selectedProvinceCart) {
        return this.iSubdistrict.filter(ele => (ele.changwat_id === this.selectedProvinceCart))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      return this.iSubdistrict.map(item => ({
        ampur_id: item.ampur_id,
        sub_district_name: item.sub_district_name,
        tumbon_id: item.tumbon_id,
        changwat_id: item.changwat_id,
      }))
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  mounted() {
    this.sub_role = []
    this.GetSubrole()
    this.GetModuleList()
    this.GetGroupUserList()
    this.getProvince()
    this.getDistrict()
    this.getSubdistrict()
    this.getzipcode()
    this.getSelect()
  },
  methods: {
    addNewbank() {
      this.$refs.addNewbank.validate().then(success => {
        if (success) {
          if (!this.itemcheck) {
            const obj = {
              name_bank: this.bank_obj.name,
              bank_code: this.bank_obj.bankcode,
              name_account: this.name_account,
              number_account: this.number_account,
            }
            this.itemBank.push(obj)
          }
          this.$bvModal.hide('modal-receive-add-amend')
        }
      })
    },
    async getSelect() {
      try {
        const { data: res } = await this.$http.get('/bank')
        this.itemBankList = res.map(ele => ({
          id: ele.id,
          bankcode: ele.bankcode,
          name: ele.name,
          path_photo: `/bankIcon/${ele.path_photo}`,
        }))
      } catch (e) {
        console.log(e)
      }
    },
    EditType(data, index) {
      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        const obj = {
          index,
          name_bank: data.name_bank,
          name_account: data.name_account,
          number_account: data.number_account,
          bank_code: null,
        }
        obj.bank_code = this.itemBankList.find(
          ele => ele.bankcode === data.bank_code,
        )
        this.itemnew = obj
        this.itemcheck = true
      } else {
        this.name_bank = null
        this.bank_code = null
        this.name_account = null
        this.number_account = null
        this.itemcheck = false
        this.itemnew = null
        this.bank_obj = null
      }
      this.$bvModal.show('modal-receive-add-amend')
    },
    DeleteData(data, index) {
      this.$bvModal
        .msgBoxConfirm('ลบข้อมูลบัญชีธนาคารนี้?', {
          title: 'ยืนยันการลบข้อมูล',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(result => {
          if (result) {
            this.itemBank.splice(index, 1)
              .then(() => {
                this.Success('ลบบัญชีธนาคารเรียบร้อย')
              })
              .catch(err => {
                console.log(err)
                this.SwalError(err)
              })
          }
        })
    },
    async GetGroupUserList() {
      try {
        this.group_userloading = true
        const { data: res } = await this.$http.get('/groupbyuser/list/index')
        this.group_user_list = res
        this.group_userloading = false
      } catch (error) {
        console.log(error)
      }
    },
    preorder(item, Type) {
      if (item) {
        const re = item.filter(ele => ele.product_type_class === Type)
        re.sort((a, b) => {
          if (a.product_type_initial < b.product_type_initial) {
            return 1
          } if (a.product_type_initial > b.product_type_initial) {
            return -1
          }
          return 0
        })
        return re
      }
      return []
    },
    async GetModuleList() {
      try {
        const { data: res } = await this.$http.get('/modulelist/index')
        this.module_list = res.data
      } catch (error) {
        console.log(error)
      }
    },
    async GetSubrole() {
      try {
        const { data: res } = await this.$http.get('/moduledetail/index')
        this.subrole = res
        this.subrole.forEach(item => {
          this.$set(item, 'status', false)
        })
      } catch (error) {
        console.log(error)
      }
    },
    copycart(index) {
      if (this.itemCart) {
        this.index = index
        this.name = this.fullname
        this.phone = this.tel
        this.address = this.itemCart.address
        this.selectedProvince = Number(this.itemCart.province)
        this.selectedDistrict = Number(this.itemCart.district)
        this.selectedSubDistrict = Number(this.itemCart.subdistrict)
        this.postalcode = this.itemCart.zipcode
      }
    },
    showAddAddrCart(data) {
      if (data) {
        this.addressCart = data.address
        this.selectedProvinceCart = Number(data.province)
        this.selectedDistrictCart = Number(data.district)
        this.selectedSubDistrictCart = Number(data.subdistrict)
        this.zipcodeCart = data.zipcode
      } else {
        this.addressCart = null
        this.selectedProvinceCart = null
        this.selectedDistrictCart = null
        this.selectedSubDistrictCart = null
        this.zipcodeCart = null
      }
      this.$bvModal.show('add-addr-cart')
    },
    addcart() {
      this.$refs.addRulescart.validate().then(success => {
        if (success) {
          const obj = {
            address: this.addressCart,
            province: this.selectedProvinceCart,
            district: this.selectedDistrictCart,
            subdistrict: this.selectedSubDistrictCart,
            zipcode: this.zipcodeCart,
            province_name: '',
            district_name: '',
            subdistrict_name: '',
          }
          if (this.selectedProvinceCart) {
            const provi = this.itemspvc.find(ele => (ele.province_id === this.selectedProvinceCart))
            obj.province_name = provi.province_name
          }
          if (this.selectedDistrictCart) {
            const arr = this.idistrict.find(ele => (ele.district_id === this.selectedDistrictCart))
            obj.district_name = arr.district_name
          }
          if (this.selectedSubDistrictCart) {
            const arr = this.iSubdistrict.find(ele => (ele.tumbon_id === this.selectedSubDistrictCart))
            obj.subdistrict_name = arr.sub_district_name
          }
          this.itemCart = obj
          this.$bvModal.hide('add-addr-cart')
        }
      })
    },
    addAddr(index) {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          if (index != null && index >= 0) {
            const arrs = {
              fullname: this.name,
              tel: this.phone,
              address: this.address,
              province: '',
              province_id: this.selectedProvince,
              district: '',
              district_id: this.selectedDistrict,
              subdistrict: '',
              subdistrict_id: this.selectedSubDistrict,
              postalcode: this.postalcode,
            }
            if (this.selectedProvince) {
              const provi = this.itemspvc.find(ele => (ele.province_id === this.selectedProvince))
              arrs.province = provi.province_name
            }
            if (this.selectedDistrict) {
              const arr = this.idistrict.find(ele => (ele.district_id === this.selectedDistrict))
              arrs.district = arr.district_name
            }
            if (this.selectedSubDistrict) {
              const arr = this.iSubdistrict.find(ele => (ele.tumbon_id === this.selectedSubDistrict))
              arrs.subdistrict = arr.sub_district_name
            }
            this.Address[index] = arrs
            this.$set(this.Address, index, arrs)
            this.$bvModal.hide('add-addr')
          } else {
            const Obj = {
              fullname: this.name,
              tel: this.phone,
              address: this.address,

              province: '',
              province_id: this.selectedProvince,
              district: '',
              district_id: this.selectedDistrict,
              subdistrict: '',
              subdistrict_id: this.selectedSubDistrict,
              postalcode: this.postalcode,
            }
            if (this.selectedProvince) {
              const provi = this.itemspvc.find(ele => (ele.province_id === this.selectedProvince))
              Obj.province = provi.province_name
            }
            if (this.selectedDistrict) {
              const arr = this.idistrict.find(ele => (ele.district_id === this.selectedDistrict))
              Obj.district = arr.district_name
            }
            if (this.selectedSubDistrict) {
              const arr = this.iSubdistrict.find(ele => (ele.tumbon_id === this.selectedSubDistrict))
              Obj.subdistrict = arr.sub_district_name
            }
            this.Address.push(Obj)
            this.$bvModal.hide('add-addr')
          }
        }
      })
    },
    SubRole(data) {
      console.debug(data)
      this.sub_role = this.sub_role.filter(item => item !== data.level)
      if (data.status) {
        this.sub_role.push(data.level)
        console.debug(this.sub_role)
      } else {
        this.sub_role = this.sub_role.filter(item => item !== data.level)
      }
    },
    Submit() {
      const subroleJs = this.subrole.filter(item => item.status)
      if (!this.itemCart) {
        this.SwalError('กรุณากรอกข้อมูลบัตรประชาขน')
      } else if (!subroleJs.length) {
        this.SwalError('กรุณาเลือกรายละเอียดโมดูล')
      } else if (!this.group_use) {
        this.SwalError('กรุณาเลือกกลุ่มผู้ใช้งาน')
      } else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            const subrole = subroleJs.map(item => item.level)
            const formData = {
              role: this.role.value,
              email: this.email,
              fullname: this.fullname,
              tel: this.tel,
              tel2: this.tel2,
              different: this.different,
              invited_by: this.invited_by,
              username: this.username,
              password: this.password,
              // sub_role: this.sub_role,
              sub_role: subrole.toString(),
              module_id: this.module_use.id,
              wechat_id: this.wechat_id,
              line_id: this.line_id,

              address: this.itemCart.address,
              province: this.itemCart.province,
              district: this.itemCart.district,
              subdistrict: this.itemCart.subdistrict,
              zipcode: this.itemCart.zipcode,
              province_name: this.itemCart.province_name,
              district_name: this.itemCart.district_name,
              subdistrict_name: this.itemCart.subdistrict_name,
              itemBank: this.itemBank,
              Address: this.Address,
              ProductType: {
              // eslint-disable-next-line no-underscore-dangle
                group_id: this.group_use._id,
                group_type: this.group_user_type,
                group_name: this.group_use.name,
                group_detail: this.group_use.detail,
                product_type: this.group_use.product_type,
              },
              invite: this.ReferralCode,
              invite_code: this.ReferralCodeValue,
            }
            this.$http
              .post('/users/Adminstore', formData)
              .then(() => {
                this.Success('เพิ่มผู้ใช้งาน')
                this.role = null
                this.email = null
                this.fullname = null
                this.tel = null
                this.tel2 = null
                this.different = null
                this.invited_by = null
                this.username = null
                this.password = null
                this.Groupbyuser()
                this.$router.push({ name: 'admin-employee' })
              })
              .catch(error => {
                this.SwalError(error.response.data.message)
              })
          }
        })

      //   }
      // })
      }
    },
    deleteAddrCart() {
      this.itemCart = null
      this.address = null
      this.selectedProvince = null
      this.selectedSubDistrict = null
      this.postalCode = null
      this.selectedDistrict = null
    },
    Groupbyuser() {
      const obj = {
        // eslint-disable-next-line no-underscore-dangle
        ID: this.group_use._id,
        name: this.group_use.name,
        product_type: this.group_use.product_type,
      }
      this.$http
        .post('/groupbyuser/list/storeAndupdate', obj)
        .catch(error => {
          console.log(error)
          // this.SwalError(error.response.data.message)
        })
    },
    CalModule() {
      const moduleArr = this.module_use.sub_role.split(',')
      this.subrole.forEach(item => {
        if (moduleArr.includes(item.level.toString())) {
          this.$set(item, 'status', true)
        } else {
          this.$set(item, 'status', false)
        }
      })
    },
    // CalGroupUser() {
    // @input="CalGroupUser()"
    //   if (this.group_use) {
    //     // eslint-disable-next-line
    //     for (const Index in this.itemsCar) {
    //       this.itemsCar[Index].kg = this.group_use[this.itemsCar[Index].key_kg]
    //       this.itemsCar[Index].queue = this.group_use[this.itemsCar[Index].key_q]
    //     }
    //     // eslint-disable-next-line
    //     for (const Index in this.itemsShip) {
    //       this.itemsShip[Index].kg = this.group_use[this.itemsShip[Index].key_kg]
    //       this.itemsShip[Index].queue = this.group_use[this.itemsShip[Index].key_q]
    //     }
    //   }
    // },
    async getProvince() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/province') // จังหวัด
        this.itemspvc = res
      } catch (e) {
        console.log(e)
      }
    },
    async getDistrict() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/district') // อำเภอ
        this.idistrict = res
      } catch (e) {
        console.log(e)
      }
    },
    async getSubdistrict() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/Subdistrict') // ตำบล
        this.iSubdistrict = res
      } catch (e) {
        console.log(e)
      }
    },
    async getzipcode() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/zipcode') // ตำบล
        this.izipcode = res
      } catch (e) {
        console.log(e)
      }
    },
    updateDistricts() {
      this.selectedDistrict = null
      this.selectedSubDistrict = null
    },
    updateSubDistricts() {
      this.selectedSubDistrict = null
      this.postalcode = null
    },
    updatePostalcode() {
      const arr = this.izipcode.find(ele => (ele.tum_id === this.selectedSubDistrict))
      this.postalcode = arr.zipcode
    },
    updateDistrictsCart() {
      this.selectedDistrictCart = null
      this.selectedSubDistrictCart = null
    },
    updateSubDistrictsCart() {
      this.selectedSubDistrictCart = null
      this.zipcodeCart = null
    },
    updatePostalcodeCart() {
      const arr = this.izipcode.find(ele => (ele.tum_id === this.selectedSubDistrictCart))
      this.zipcodeCart = arr.zipcode
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAddAddr(data, index) {
      if (data) {
        this.index = index
        this.name = data.fullname
        this.phone = data.tel
        this.address = data.address
        this.selectedProvince = data.province_id
        this.selectedDistrict = data.district_id
        this.selectedSubDistrict = data.subdistrict_id
        this.postalcode = data.postalcode
      } else {
        this.index = null
        this.name = null
        this.phone = null
        this.address = null
        this.selectedProvince = null
        this.selectedDistrict = null
        this.selectedSubDistrict = null
        this.postalcode = null
      }
      this.$bvModal.show('add-addr')
    },
    showAddAddrCard(data, index) {
      if (data) {
        this.index = index
        this.name = data.name
        this.phone = data.phone
        this.address = data.address
        this.selectedProvince = data.province_id
        this.selectedDistrict = data.district_id
        this.selectedSubDistrict = data.subdistrict_id
        this.postalcode = data.postalcode
      } else {
        this.index = null
        this.name = null
        this.phone = null
        this.address = null
        this.selectedProvince = null
        this.selectedDistrict = null
        this.selectedSubDistrict = null
        this.postalcode = null
      }
      this.$bvModal.show('add-addr')
    },
    deleteAddr(index) {
      this.Address.splice(index, 1)
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
